<template>
    <div class="page-services container">
        <div class="row">
            <div class="col-12">
                <app-table
                    class="catalogue-table"

                    :cols="catalogue.cols"
                    :rows="catalogue.list"

                    title="Service Catalogue"

                    :sort-value="catalogue.filter.sort"
                    :sort-options="catalogue.sort"
                    @sort-change="onServicesSortChange"

                    :clickable="true"
                    @row-click="openDetails"
                    @row-click-mobile="openDetails"

                    :loading="loading.catalogue"
                >
                    <template #header-actions>
                        <button class="btn btn-small btn-primary" @click="dialogs.adding.show = true" v-if="with_services_adding">Add Service</button>
                    </template>
                </app-table>
            </div>
        </div>



        <dialog-adding-service
            v-if="dialogs.adding.show"
            v-model="dialogs.adding.show"
        ></dialog-adding-service>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'

import dialogAddingService from './dialogs/dialog-adding-service'

export default {
    components: {
        appTable,
        appPagination,

        dialogAddingService,
    },

    data() {
        return {
            catalogue: {
                list: [],
                
                filter: {
                    word: '',
                    previous: '',
                    sort: {
                        field: 'Name',
                        order: 'asc'
                    },
                },

                sort: [
                    { field: 'Name',        title: 'Name'        },
                    { field: 'UUID',        title: 'UUID' },
                    { field: 'ServiceType', title: 'ServiceType' },
                ],

                cols: [
                    { key: 'Name',        title: 'Name',         sort: { field: 'Name'        }, highlight: true },
                    { key: 'UUID',        title: 'UUID',         sort: { field: 'UUID'        },                 },
                    { key: 'ServiceType', title: 'Service Type', sort: { field: 'ServiceType' },                 },
                ],
            },

            dialogs: {
                adding: {
                    show: false,
                },
            },

            loading: {
                catalogue: false,
            },
        }
    },

    methods: {
        init(to, from) {
            this.getServices(this.filter)
        },

        getServices(filter) {
            this.loading.catalogue = true

            filter = {
                ...this.catalogue.filter,
                ...filter,
            }

            let params = {
                'SearchOptions.SortBy': filter.sort.field,
                'SearchOptions.SortDesc': filter.sort.order === 'desc' ? 1 : 0,
            }

            const word = filter.word.trim()

            if (word.length > 0) {
                params = {
                    ...params,
                    'SmartSearch': word,
                    SPID: this.current_spid,
                }

                this.catalogue.filter.word = word
                this.catalogue.filter.previous = word
            }

            return this.$store.dispatch('api_service_catalog/FindServiceCatalogEntriesPaginated', params).then(response => {
                this.catalogue.list = response.ServiceCatalogEntries.filter(service => !service.DeletedAtNanos)

                this.loading.catalogue = false
            }).catch(error => {
                console.error(error)

                this.catalogue.list = []

                this.loading.catalogue = false
            })
        },

        openDetails(service) {
            this.$router.push({ name: 'service', params: { uuid: service.UUID } })
        },

        /**
         * Filtering
         */
        onServicesSortChange(sort) {
            this.catalogue.filter.sort = sort
            this.getServices()
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',

            'services_adding_types',
        ]),

        with_services_adding() {
            return Object.keys(this.services_adding_types).length
        },
    },

    beforeRouteEnter(to, from, next) {
        next(route => { route.init( to, from ) })
    },
}
</script>

<style lang="scss">
.page-services {
    padding-bottom: 80px;

    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .catalogue-table {
        @include table-cols-width((250px, 250px, 120px), true);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-services {
        .app-add-provider {
            margin-top: 30px;
        }

        .catalogue-table {
            @include table-cols-width((150px, 150px, 100px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-services {
        .catalogue-table {
            @include table-cols-width-mobile((70px, 205px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }

        .app-add-provider {
            margin-top: 15px;
        }
    }
}
</style>